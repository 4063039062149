const data = [
  {
    _id: "666d016b64b583660408f059",
    h1: "Restaurant Order Booking App",
    Tech: [
      "React.Js",
      "Express.js",
      "Tailwind",
      "MongoDB",
      "LLM Api",
      "Node.Js",
      "Gpt3.5Turbo",
    ],
    code: "https://github.com/ShivRajSingh-SDE/RestaurantApp",
    commit: 8,
    live: "https://elegancefood.vercel.app/665850b13424da140f2d2011",
    bgImg: "https://i.imgur.com/SxqmEXD.jpeg",
  },
  {
    _id: "666d016164b583660408f057",
    h1: "Smart E-com site",
    Tech: ["React.Js", "Express.js", "Tailwind", "MongoDB", "Mui", "Node.Js"],
    code: "https://github.com/ShivRajSingh-SDE",
    commit: 32,
    live: "https://bizzybazaar.vercel.app/",
    bgImg: "https://i.imgur.com/Z3xgzRm.png",
  },
  {
    _id: "666d017264b583660408f05b",
    h1: "Smart Education Based Web App",
    Tech: [
      "React.Js",
      "Express.js",
      "Tailwind",
      "MongoDB",
      "LLM Api",
      "Node.Js",
    ],
    code: "https://github.com/ShivRajSingh-SDE/education4.0",
    commit: 12,
    live: "https://apptechtells.netlify.app/",
    bgImg: "https://i.imgur.com/Xmy6EFX.png",
  },
];

export default data;

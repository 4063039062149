// src/component/Project.js
import React, { useEffect, useState } from "react";
import dummyData from "../data";
import "../css/project.css";
import LoadingScreen from "../pages/LoadingScreen";

const Project = () => {
  const [productData, setProductData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProductData = async () => {
      // Simulate a delay for loading screen
      await new Promise((resolve) => setTimeout(resolve, 4000));
      setProductData(dummyData);
      setLoading(false);
    };

    fetchProductData();
  }, []);

  return (
    <div id="Project" className="max-w-[80%] mx-auto">
      <br />
      <br />
      <div id="textstyle">
        <h1 className="text-3xl border-l-2 font-bold border-[#389cca] p-2 text-start my-10">
          Projects
        </h1>
        <br />
        <br />
      </div>
      {loading ? (
        <div className="flex flex-col justify-center items-center">
          <LoadingScreen />
        </div>
      ) : (
        <div className="flex flex-wrap justify-around space-y-4">
          {productData.map((item, i) => (
            <div className="card1" key={i}>
              <div
                className="top-section"
                style={{
                  backgroundImage: `url(${item.bgImg})`,
                  backgroundSize: "cover",
                }}
              >
                <div className="border"></div>
                <div className="icons relative">
                  <div className="logo"></div>
                  <div className="absolute flex flex-row justify-center items-center">
                    <a
                      href="https://www.instagram.com/developer_shiv_/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        width="20"
                        className="bg-white rounded-3xl hover:scale-125 duration-300 ease-in-out p-1 ml-5"
                        height="25"
                        viewBox="0 0 50 50"
                      >
                        <path d="M 16 3 C 8.8324839 3 3 8.8324839 3 16 L 3 34 C 3 41.167516 8.8324839 47 16 47 L 34 47 C 41.167516 47 47 41.167516 47 34 L 47 16 C 47 8.8324839 41.167516 3 34 3 L 16 3 z M 16 5 L 34 5 C 40.086484 5 45 9.9135161 45 16 L 45 34 C 45 40.086484 40.086484 45 34 45 L 16 45 C 9.9135161 45 5 40.086484 5 34 L 5 16 C 5 9.9135161 9.9135161 5 16 5 z M 37 11 A 2 2 0 0 0 35 13 A 2 2 0 0 0 37 15 A 2 2 0 0 0 39 13 A 2 2 0 0 0 37 11 z M 25 14 C 18.936712 14 14 18.936712 14 25 C 14 31.063288 18.936712 36 25 36 C 31.063288 36 36 31.063288 36 25 C 36 18.936712 31.063288 14 25 14 z M 25 16 C 29.982407 16 34 20.017593 34 25 C 34 29.982407 29.982407 34 25 34 C 20.017593 34 16 29.982407 16 25 C 16 20.017593 20.017593 16 25 16 z"></path>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
              <div className="bottom-section">
                <span className="title">{item.h1}</span>
                <div className="row row1 flex flex-col">
                  <div className="flex flex-row">
                    <div className="item">
                      <span className="big-text">MERN</span>
                      <span className="regular-text">Tech Stack</span>
                    </div>
                    <div className="item">
                      <span className="big-text">100%</span>
                      <span className="regular-text">Free for use</span>
                    </div>
                    <div className="item">
                      <span className="big-text">{item.commit}</span>
                      <span className="regular-text">Commits</span>
                    </div>
                  </div>

                  <div className="flex flex-row mt-5 justify-between items-center">
                    <button className="cv">
                      <a
                        target="_blank"
                        href={item.code}
                        rel="noopener noreferrer"
                      >
                        Code
                      </a>
                    </button>
                    <button className="cv">
                      <a
                        target="_blank"
                        href={item.live}
                        rel="noopener noreferrer"
                      >
                        Live Demo
                      </a>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Project;

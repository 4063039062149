import React from "react";
import Main from "../main";
import About from "../about";
import Work from "../work";
import Project from "../project";
import Contact from "../contact";
import Certificates from "../certificates";

const HomePage = () => {
  return (
    <div className="">
      <Main />
      <About />
      <Work />
      <Project />
      <Certificates />
      <Contact />
    </div>
  );
};

export default HomePage;
